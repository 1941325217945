import axios from 'axios';

const SaveInterviewCandidateImage = async (formData, zoho_candidate_id) => {
 
   const Candidate_ID =zoho_candidate_id;
   
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + 'api/interview/save-candidate-profile-picture?Candidate_ID=' + Candidate_ID,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    //token: token
                },
            }
        );
        
        return response.data;
    } catch (error) {
        console.error('Error saving candidate image:', error);
        throw error; 
    }
};

export default SaveInterviewCandidateImage;
