import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styles from '../Boading.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/images/logo.svg';

const TodayInterview = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        // Check for correct username and password
        if (username === 'bluent' && password === 'bluent@123') {
            setIsLoggedIn(true);
            toast.success('Login successful!');
            navigate('/interview-candidates');
        } else {
            toast.error('Invalid username or password.');
        }
    };

    return (
        <>
            <Helmet>
                <title>Login Form</title>
            </Helmet>
            <section className={`${styles['box-shadow']} ${styles.container}`}>
                <div className={styles.header}>
                    <div className={styles.grid}>
                        <div className={styles.col12}>
                            <ul>
                                <li className={styles['li-first']}>
                                    <div className={styles.logo}>
                                        <img src={Logo} alt="Logo" width="195" height="42" />
                                    </div>
                                </li>
                                <li className={styles['li-third']}>
                                    <div className={styles['align-center']} style={{ color: '#9393ff', fontWeight: '500', fontSize: '1.5rem' }}>
                                        Login Form
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={styles.employee}>
                    <div className={styles.grid}>
                        <div className="Boading_edit-bg__53BA+"></div>

                        <div className={styles.col9}>
                            <div className={`${styles['box-shadow']} ${styles['border-radius']} `}>
                                <fieldset className={styles.fieldset}>
                                    <legend style={{ paddingBottom: "20px" }}>Today's Interview</legend>
                                    <form onSubmit={handleLogin} className={styles.interviewloginForm}>
                                        <div className={styles.grid}>
                                            <div className={styles.col6}>
                                                <div className={styles['right-space']}>
                                                    <ul className={styles['two-col']}>
                                                        <li>
                                                            <strong>Username:</strong>
                                                            <span>
                                                                <input
                                                                    type="text"
                                                                    id="username"
                                                                    value={username}
                                                                    className={styles.input}
                                                                    onChange={(e) => setUsername(e.target.value)}
                                                                    required
                                                                />
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>Password:</strong>
                                                            <span>
                                                                <input
                                                                    type="password"
                                                                    id="password"
                                                                    className={styles.input}
                                                                    value={password}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    required
                                                                />
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {/* Button block */}
                                            <div className={styles['button-block']}>
                                                <input type="submit" value="Save" className={styles.button} />
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </section>
        </>
    );
};

export default TodayInterview;
