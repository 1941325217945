import React, { useState, useEffect, useRef  } from 'react';
import { useToken } from '../../context/AuthContext'
import styles from './Admin.module.scss';
import Table from '../../components/Table/Table';
import View from '../../assets/images/view.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CandidateDetail from './CandidateDetail';
import FeedbackDetail from './FeedbackDetail';
import { Helmet } from 'react-helmet-async';
import OffBoadingEmployeeList from '../../api/OffBoadingEmployeeList';
import { Navigate } from "react-router-dom";
import Download from '../../assets/images/pdf-download.svg';
import axios from 'axios';
import Copy from '../../assets/images/copy.svg';
import Email from '../../assets/images/email.svg';
const OffboadingEmployeeDetail = ({deptname,activeTab,setActiveTab,authenticateToken, tabs, showCopyButton}) => {

     const { token } = useToken();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [showCandidateDetail, setCandidateDetail] = useState(false);
    const [showFeedback, setFeedback] = useState(false);
    const [tokenValue, settoken] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [disableTabs, setDisableTabs] = useState([false, false]);
    const [employeeId, setEmployeeId] = useState(null); 
    const [tabVisibility, setTabVisibility] = useState([true, true, true]); // Track visibility of each tab
    const tabTitles = [
        'Candidate',
        'Onboarding',
        'Offboarding',



    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentTabProgress, setCurrentTabProgress] = useState([]);
    const activeTabRef = useRef(activeTab);
    useEffect(() => {
        const fetchData = async () => {
                try {
                    setLoading(true);
                    const candidateData = await OffBoadingEmployeeList(authenticateToken);
                    setData(candidateData.data);
                    const pages = Math.ceil(candidateData.data.length / pageSize);
                    setTotalPages(pages);
                } catch (error) {
                    console.error('Error fetching employee data:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        
    }, []);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const activeTabsParam = parseInt(urlParams.get('activeTab'), 10) || 0;
        const tabIndices = {
            'Candidate': 0,
            'Onboarding': 1,
            'Offboarding': 2,
        };

        const newDisableTabs = Array(3).fill(true);
        const activeIndices = tabs.map(tab => tabIndices[tab]);

        activeIndices.forEach(index => {
            if (index !== undefined) {
                newDisableTabs[index] = false;
            }
        });

        const newTabVisibility = newDisableTabs.map(disabled => !disabled);

        let newActiveTab = 0;

        if (activeTabsParam !== undefined && !newDisableTabs[activeTabsParam]) {
            newActiveTab = activeTabsParam;
        } else {
            const firstVisibleTabIndex = newTabVisibility.findIndex(visible => visible);
            if (firstVisibleTabIndex !== -1) {
                newActiveTab = firstVisibleTabIndex;
            }
        }

      if (activeTabRef.current !== newActiveTab) {
            activeTabRef.current = newActiveTab;
            //console.log('fff'+ activeTabRef.current);
            //setActiveTab(activeTabRef.current);
        }

        setDisableTabs(newDisableTabs);
        setTabVisibility(newTabVisibility);
    }, [deptname, tabs, setActiveTab]);

    function handleSort(column) {
        const sortedData = [...data];
        const currentSort = sortState[column] || "asc";
        sortedData.sort((a, b) => {
            if (currentSort === "asc") {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });
        const newSortState = {
            ...sortState,
            [column]: currentSort === "asc" ? "desc" : "asc"
        };
        setData(sortedData);
        setSortState(newSortState);
    }
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filteredData = data.filter((item) => {
            return (
                (item.first_name && item.first_name.toLowerCase().includes(query.toLowerCase())) ||
                (item.last_name && item.last_name.toLowerCase().includes(query.toLowerCase())) ||
                (item.manager_name && item.manager_name.toLowerCase().includes(query.toLowerCase())) ||
                (item.designation && item.designation.toLowerCase().includes(query.toLowerCase())) ||
                (item.department_name && item.department_name.toLowerCase().includes(query.toLowerCase())) ||
                (item.releaving_date && item.releaving_date.toLowerCase().includes(query.toLowerCase())) ||
                (item.doj && item.doj.toLowerCase().includes(query.toLowerCase()))||
                (item.status && item.status.toLowerCase().includes(query.toLowerCase()))
            );
        });
        
        const pages = Math.ceil(filteredData.length / pageSize);
        setTotalPages(pages);
    };

    const filteredData = data.filter((item) =>
        item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.manager_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.designation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.department_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.releaving_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.doj.toLowerCase().includes(searchQuery.toLowerCase())||
        item.status.toLowerCase().includes(searchQuery.toLowerCase())
        
    );

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const handleClosePopup = () => {
        setCandidateDetail(false);
        setFeedback(false);
    };
    const sendEmployeeDetail = async (employeeId, managerId) => {
        setEmployeeId(employeeId);
        localStorage.setItem('managerID', managerId);
        setRedirect(true);
       
       
    };
    
    const handleTabClick = (index) => {
        if (tabVisibility[index]) {
            setActiveTab(index);
        }
    };

    const handleCopy = async (candidateId) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/offboarding/generate-employee-responsibility-form-link`,
                {
                    blitz_user_id: candidateId,
                    email: false,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            
            if (response.data) {
                setLoading(false);
                let dataToCopy = response.data.link;
                dataToCopy = dataToCopy.replace(/"/g, '');
                navigator.clipboard.writeText(dataToCopy)
                    .then(() => {
                        toast.success('Employee Responsibility link copied successfully', {
                            duration: 4000,
                        });
                    })
                    .catch((error) => {
                        toast.error('Error copying to clipboard', {
                            duration: 4000,
                        });
                    });
            } else {
                toast.error('Failed to fetch data from the API', {
                    duration: 4000,
                });
            }
        } catch (error) {
            toast.error('Error fetching data from the API', {
                duration: 4000,
            });
        }
    };

    const sendEmail = async (candidateId) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/offboarding/generate-employee-responsibility-form-link`,
                {
                    blitz_user_id: candidateId,
                    email: true,
                },
                {
                    headers: {
                        'token': authenticateToken,
                        'accept': 'application/json',
                    },
                }
            );
            if (response.data) {
                setLoading(false);
                toast.success(response.data.msg, {
                    duration: 4000,
                });
            } else {
                setLoading(false);
                toast.success('Failed to send email', {
                    duration: 4000,
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error sending email:', error);
        }
    };
    

    
    
    const columns = [
        {
            title: 'Employee Name',
            dataKey: 'employee_name',
            renderer: (rowData) => `${rowData.first_name} ${rowData.last_name}`,
            sortable: true,
            minwidth: 170
        },
        { title: 'Manager Name', dataKey: 'manager_name', sortable: true, minwidth: 170 },
        {
            title: 'Designation',
            dataKey: 'designation',
            sortable: true,

            minwidth: 145,
        },
        {
            title: 'Department',
            dataKey: 'department_name',
            sortable: true,
            minwidth: 155,
        },
        {
            title: 'Joining Date',
            dataKey: 'doj',
            sortable: true,
            minwidth: 148,
        },
        {
            title: 'Relieving Date',
            dataKey: 'releaving_date',
            sortable: true,
            minwidth: 165,
        },
        ...(showCopyButton ? [{
            title: 'Employee Form',
            dataKey: 'blitz_user_id',
            renderer: (rowData) => (
                <div className={styles.toolTip} key={rowData.blitz_user_id}>
                    <button
                        onClick={() => handleCopy(rowData.blitz_user_id)}>
                        <img src={Copy} alt="Copy" width="20" height="20" />
                    </button>
                    <button
                        onClick={() => sendEmail(rowData.blitz_user_id)}>
                        <img src={Email} alt="Email" width="20" height="20" />
                    </button>
                </div>
            ),
            minwidth: 149,
        }] : []),
        
        
        {
            title: 'Status',
            dataKey: 'status',
            sortable: true,
            minwidth: 120,
        },
       
        {
            title: 'Details',
            dataKey: 'blitz_user_id',
            renderer: (rowData) => (
                <div className={styles.toolTip} key={rowData.blitz_user_id}>
                    <button
                        className="custom-tooltip"
                        onClick={() => sendEmployeeDetail(rowData.blitz_user_id, rowData.manager_id)}>
                        
                        <img src={View} alt="First Action" width="20" height="20" />
                        
                    </button>
                    
                    
                </div>
            ),
            minwidth: 90,
        },
        
    ];

    const disabledTabClass = styles.disabledTab;

    return (
        <>
            <Helmet>
                <title>Employee List</title>

            </Helmet>


            {showCandidateDetail ? (
                <CandidateDetail url={tokenValue} onClose={handleClosePopup} />
            ) : showFeedback ? (
                <FeedbackDetail url={tokenValue} onClose={handleClosePopup} />
            ) : (
                <>

                    {activeTab === 2 && (
                        <>
                         <div className={styles.grid}>
                                <div className={styles.col8}>
                                    <ul className={styles.tab}>
                                        {tabTitles.map((title, index) => (
                                            <li key={index}>
                                                <a
                                                            href="#"
                                                            className={`${index === activeTab ? styles.current : ''} ${disableTabs[index] ? disabledTabClass : ''}`}
                                                            onClick={() => handleTabClick(index)}
                                                            style={disableTabs[index] ? { pointerEvents: 'none', background: '#c5c6c7', color: 'gray' } : {}}
                                                        >
                                                            {title}
                                                        </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={styles.col4}>
                                    <div className={`${styles['search-icon']} ${styles['search-space-top']}`}>
                                        <input type="search" onChange={(e) => handleSearch(e.target.value)} className={styles['search-field']} placeholder="Search …" value={searchQuery} name="s" title="Search for:" />
                                    </div>
                                </div>
                            </div>
                        
                        <div className={`${styles.grid} ${styles['grid-height']}`}>
                           {redirect && (
                               <Navigate to={`/offboarding?token=${token}&employeeId=${employeeId}`} replace={true} />
                             )}
                            <div className={styles['dashboard-table1']}>
                                <Table
                                    data={filteredData}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onSort={handleSort}
                                    loading={loading}
                                    pagination={pagination}
                                    onChangePage={handleChangePage}
                                    total={totalPages}
                                    activePage={currentPage}
                                    limit={totalPages}
                                    minWidth={20}
                                />
                            </div>
                        </div>
                        </>
                    )}
             

                    <ToastContainer />
                </>
                
            )}



        </>
    );

};

export default OffboadingEmployeeDetail; 
